import { LoadingButton } from "@mui/lab";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  successToast,
  textCapitalize,
} from "../../utils/common";
import ProviderSettingsInfoModal from "../Providers/providerSettingsInfoModal";
import { issueTypeCategoryData } from "./issueTypeCategoryView";

const CreateTicketModal = ({ open, onClose, getIssuesList }: any) => {
  const [mobile, setMobile] = useState("");
  const [loading, setLoading] = useState(false);
  const [creating, setCreating] = useState(false);
  const privateInstance = useAxiosInstance();
  const [providerData, setProviderData] = useState<any>({});
  const [issueType, setIssueType] = useState("");
  const [issueSubType, setIssueSubType] = useState("");
  const [textContent, setTextContent] = useState("");
  const [showProviderSettingsInfoModal, setShowProviderSettingsInfoModal] =
    useState(false);

  const getProvider = async () => {
    setProviderData({});
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/location/search?by=whatsapp_number&value=${mobile}`
      );
      if (Object.keys(result.data.response).length !== 0) {
        setProviderData(result.data.response);
      } else {
        errorToast(result.data.message);
        setProviderData({});
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const createTicket = async () => {
    let payload: any = {
      issue_type: issueType,
      issue_sub_type: issueSubType,
      description: {
        text: textCapitalize(textContent),
        images: [],
      },
      location_id: providerData?.location_id,
      provider_name: providerData?.name,
    };

    setCreating(true);

    try {
      const result = await privateInstance.post(
        `${API_VERSION.V1}/issues`,
        payload
      );
      if (result.data?.status === API_RESULT.SUCCESS) {
        successToast("Ticket Created Successfully");
        onClose();
        //update
        getIssuesList();
      } else {
        errorToast(result?.data?.message);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setCreating(false);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Create Ticket
      </CustomDialogTitle>
      <DialogContent>
        <Stack alignItems="center">
          <>
            <TextField
              fullWidth
              id="mobile"
              label="Pharmacy Number"
              size="small"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              helperText={
                mobile.length > 1 && mobile.length < 10
                  ? "Please enter 10 digit number"
                  : ""
              }
              error={mobile.length > 1 && mobile.length < 10 ? true : false}
              inputProps={{
                maxLength: 10,
              }}
              sx={{
                mt: 1.5,
              }}
            />
            <LoadingButton
              variant="contained"
              color="primary"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              disabled={mobile.length !== 10}
              sx={{ mt: 2 }}
              onClick={getProvider}
            >
              Search
            </LoadingButton>
          </>
          {Object.keys(providerData).length > 0 && (
            <Box
              sx={{
                mt: 3,
                width: "100%",
              }}
            >
              <TextField
                fullWidth
                id="provider-name"
                label="Pharmacy Name"
                size="small"
                value={providerData?.name}
                sx={{ mt: 1.5 }}
                disabled
                InputLabelProps={{
                  required: true,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <Button
                        onClick={() => setShowProviderSettingsInfoModal(true)}
                        size="small"
                        color="info"
                      >
                        More Info
                      </Button>
                    </InputAdornment>
                  ),
                }}
              />
              <TextField
                fullWidth
                multiline
                rows={2}
                id="provider-address"
                label="Address"
                size="small"
                value={providerData?.formatted_address}
                sx={{ mt: 1.5 }}
                disabled
                InputLabelProps={{
                  required: true,
                }}
              />
              <Box display="flex" gap={1.5}>
                <FormControl fullWidth size="small" sx={{ mt: 1.5 }}>
                  <InputLabel required id="type-label">
                    Issue Type
                  </InputLabel>
                  <Select
                    labelId="type-label"
                    name="type"
                    id="type"
                    label="Issue Type"
                    value={issueType}
                    onChange={(e) => {
                      setIssueType(e.target.value);
                    }}
                  >
                    {issueTypeCategoryData?.map((x, i) => {
                      return (
                        <MenuItem key={i} value={x?.type}>
                          {x?.type}
                        </MenuItem>
                      );
                    })}
                  </Select>
                </FormControl>
                <FormControl fullWidth size="small" sx={{ mt: 1.5 }}>
                  <InputLabel required id="category-label">
                    Category
                  </InputLabel>
                  <Select
                    labelId="category-label"
                    name="category"
                    id="category"
                    label="Category"
                    value={issueSubType}
                    onChange={(e) => {
                      setIssueSubType(e.target.value);
                    }}
                  >
                    {issueTypeCategoryData
                      ?.find((t) => t.type === issueType)
                      ?.categories?.map((x, i) => {
                        return (
                          <MenuItem key={i} value={x}>
                            {x}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
              <TextField
                fullWidth
                multiline
                rows={4}
                id="text-content"
                label="Text"
                size="small"
                value={textContent}
                onChange={(e) => setTextContent(e.target.value)}
                InputLabelProps={{
                  required: true,
                }}
                sx={{ mt: 1.5 }}
              />
              <Box textAlign="center" mt={3}>
                <LoadingButton
                  sx={{ minWidth: "180px" }}
                  size="medium"
                  color="primary"
                  loading={creating}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="button"
                  onClick={createTicket}
                  disabled={!issueType || !issueSubType || !textContent?.trim()}
                >
                  Submit
                </LoadingButton>
              </Box>
            </Box>
          )}
        </Stack>
        {showProviderSettingsInfoModal && (
          <ProviderSettingsInfoModal
            showProviderSettingsInfoModal={showProviderSettingsInfoModal}
            setShowProviderSettingsInfoModal={setShowProviderSettingsInfoModal}
            activeLocationId={providerData?.location_id}
            providersData={null}
            setProvidersData={null}
            path="app-issues"
            targetId={providerData?.location_id}
          />
        )}
      </DialogContent>
    </Dialog>
  );
};

export default CreateTicketModal;
