import { LoadingButton } from "@mui/lab";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  handleError,
  parseAndFormateDate,
  successToast,
} from "../../../utils/common";
import { soundboxProvidersList } from "../../../constants";

const ManageDevicesModal = ({
  open,
  onClose,
  rowData,
  tableData,
  setTableData,
}: any) => {
  const [soundboxId, setSoundboxId] = useState("");
  const [soundboxProvider, setSoundboxProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [registeredTerminalId, setRegisteredTerminalId] = useState("");

  const handleSubmit = async (type: string) => {
    let payload: any = {
      update_type: type,
    };
    if (type === "map") {
      payload = {
        ...payload,
        soundbox_id: soundboxId,
        soundbox_provider: soundboxProvider,
      };
    }
    if (window.confirm("Are you sure?")) {
      setLoading(true);
      try {
        const result = await privateInstance.patch(
          `${API_VERSION.V1}/manage/terminals/${rowData?.terminal_id}/soundbox`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Updated Successfully");
          onClose();
          //update table UI Data;
          const itemIndex = tableData.findIndex(
            (t: any) => t.terminal_id === rowData?.terminal_id
          );
          if (type === "map") {
            tableData.splice(itemIndex, 1, {
              ...rowData,
              soundbox_id: soundboxId,
              soundbox_provider: soundboxProvider,
              soundbox_available: true,
              soundbox_linked_at: format(
                new Date(),
                "dd MMM yyyy, hh:mm aaaaa'm'"
              ),
            });
          } else {
            delete rowData.soundbox_id;
            delete rowData.soundbox_provider;
            tableData.splice(itemIndex, 1, {
              ...rowData,
              soundbox_available: false,
            });
          }
          setTableData([...tableData]);
          //end
        }
      } catch (err: any) {
        if (err?.response?.data?.message?.includes("mapped")) {
          setRegisteredTerminalId(err.response.data.message.split(": ")[1]);
        } else {
          handleError(err);
        }
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
          Manage Soundbox
        </CustomDialogTitle>
        <DialogContent>
          {registeredTerminalId ? (
            <>
              <Typography>
                This device is already registered with another terminal ID:{" "}
                <strong>{registeredTerminalId}</strong>
              </Typography>
              <Stack alignItems="center" mt={3}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    onClose();
                    window.open(
                      `/digital-payment/qr-terminals?terminal_id=${registeredTerminalId}`
                    );
                  }}
                >
                  Check Now
                </Button>
              </Stack>
            </>
          ) : (
            <>
              {rowData?.soundbox_available ? (
                <>
                  <Typography py={1}>{rowData?.pharmacy_name}</Typography>
                  <Divider />
                  <Stack direction="row" justifyContent="space-between" pt={1}>
                    <Typography variant="h6">Device ID/Serial No:</Typography>
                    <Typography>{rowData?.soundbox_id}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between" pt={1}>
                    <Typography variant="h6">Soundbox Provider:</Typography>
                    <Typography>{rowData?.soundbox_provider}</Typography>
                  </Stack>
                  {rowData?.soundbox_linked_at ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      pt={1}
                    >
                      <Typography variant="h6">Device Using Since:</Typography>
                      <Typography>
                        {rowData?.soundbox_linked_at?.includes(",")
                          ? rowData.soundbox_linked_at
                          : parseAndFormateDate(rowData?.soundbox_linked_at)}
                      </Typography>
                    </Stack>
                  ) : null}
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    id="soundbox_id"
                    label="Device ID/Serial No"
                    size="small"
                    value={soundboxId}
                    onChange={(e) => setSoundboxId(e.target.value)}
                    inputProps={{
                      maxLength: 20,
                    }}
                    InputLabelProps={{
                      required: true,
                    }}
                    sx={{ mt: 1.5 }}
                  />
                  <FormControl size="small" fullWidth sx={{ mt: 2 }}>
                    <InputLabel required id="soundbox-provider-label">
                      Soundbox Provider
                    </InputLabel>
                    <Select
                      labelId="soundbox-provider-label"
                      name="soundbox_provider"
                      id="soundbox_provider"
                      value={soundboxProvider}
                      label="Soundbox Provider"
                      onChange={(e: SelectChangeEvent) =>
                        setSoundboxProvider(e.target.value)
                      }
                      sx={{ textTransform: "uppercase" }}
                    >
                      {soundboxProvidersList.map((x, i) => {
                        return (
                          <MenuItem
                            sx={{ textTransform: "uppercase" }}
                            key={i}
                            value={x}
                          >
                            {x}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </>
              )}
              <Stack mt={3} alignItems="center">
                <LoadingButton
                  color="primary"
                  variant="contained"
                  loading={loading}
                  disabled={
                    loading ||
                    (!rowData?.soundbox_available &&
                      (!soundboxId?.trim() || !soundboxProvider))
                  }
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  onClick={() =>
                    handleSubmit(rowData?.soundbox_available ? "demap" : "map")
                  }
                >
                  {rowData?.soundbox_available ? "Unlink" : "Link"}
                </LoadingButton>
              </Stack>
            </>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default ManageDevicesModal;
