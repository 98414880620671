import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable, { FilterType } from "mui-datatables";
import React, { useEffect, useState } from "react";
import { commonTableOptions } from "../../../constants";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError, parseAndFormateDate } from "../../../utils/common";
import { Add } from "@mui/icons-material";
import AddDeviceModal from "./addDeviceModal";

const Devices = () => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [showAddDevice, setShowAddDevice] = useState(false);

  const getData = async () => {
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/analytics/digital-payments/providers?filter=devices`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setData(result?.data?.response?.devices);
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cols = [
    {
      name: "device_id",
      label: "Device ID",
      options: {
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "partner",
      label: "Partner",
    },
    {
      name: "terminal_id",
      label: "Terminal ID",
      options: {
        filterType: "textField" as FilterType,
      },
    },
    {
      name: "soundbox_linked_at",
      label: "Link Info",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Tooltip title={data[tableMeta.rowIndex]?.soundbox_linked_by || ""}>
              <Typography minWidth={120} variant="body2">
                {parseAndFormateDate(value)}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
    {
      name: "soundbox_unlinked_at",
      label: "Unlink Info",
      options: {
        filter: false,
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Tooltip
              title={data[tableMeta.rowIndex]?.soundbox_unlinked_by || ""}
            >
              <Typography minWidth={120} variant="body2">
                {parseAndFormateDate(value)}
              </Typography>
            </Tooltip>
          );
        },
      },
    },
  ];

  const CustomToolbar = () => {
    return (
      <Button
        startIcon={<Add />}
        variant="outlined"
        size="small"
        sx={{ ml: 1.5 }}
        onClick={() => setShowAddDevice(true)}
      >
        Add Device
      </Button>
    );
  };

  const options: any = {
    customToolbar: () => <CustomToolbar />,
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Device ID/Partner/Terminal ID",
  };

  return (
    <Box>
      <MUIDataTable title="" data={data} columns={cols} options={options} />
      {showAddDevice ? (
        <AddDeviceModal
          open={showAddDevice}
          onClose={() => setShowAddDevice(false)}
          getData={getData}
        />
      ) : null}
    </Box>
  );
};

export default Devices;
