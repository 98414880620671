import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import ErrorBoundary from "../../../components/Helpers/ErrorBoundary";
import { commonTableOptions } from "../../../constants";
import {
  API_RESULT,
  API_VERSION,
  PAYMENT_CREDIT_STATUS,
} from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import {
  errorToast,
  handleError,
  parseAndFormateDate,
  amountConverter,
  successToast,
} from "../../../utils/common";
import InfoIcon from "@mui/icons-material/Info";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers";
import { format, parse } from "date-fns";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { LoadingButton } from "@mui/lab";
import ErrorIcon from "@mui/icons-material/Error";
import { useLocation } from "react-router-dom";
import RecoReportModal from "./RecoReportModal";
import AccountBalanceWallet from "@mui/icons-material/AccountBalanceWallet";
import PaymentDataRow from "../../../components/ManageOrder/GroupOrders/Payment/PaymentStatus/paymentDataRow";

const FraudModal = ({
  showFraudModal,
  setShowFraudModal,
  data,
  callbackFn,
}: any) => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleFraudPayout = async (type: string) => {
    try {
      if (
        window.confirm(
          "Are you sure? This payout will be marked as " + type + ""
        )
      ) {
        let payload = {
          payout_id: data?.payout_id,
          fraud_detection_status: type,
        };
        setLoading(true);
        const result = await privateInstance.post(
          `${API_VERSION.V1}/payments/flagged-payout`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          callbackFn(); //update list
          successToast(`Payout ${type} Successfully`);
          setShowFraudModal(false);
        } else {
          errorToast(result.data.message);
        }
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Dialog
        open={showFraudModal}
        onClose={() => setShowFraudModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="sm"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowFraudModal(false)}
        >
          Fraud Payout
        </CustomDialogTitle>
        <DialogContent>
          <Box>
            <Typography pt={1} variant="body2">
              <strong>Payout ID:</strong> {data.payout_id}
            </Typography>
            <Typography pt={1} variant="body2">
              <strong>Amount:</strong> &#8377;{data.amount}
            </Typography>
            {data.bank_reference ? (
              <Typography pt={1} variant="body2">
                <strong>Bank Ref:</strong> {data.bank_reference}
              </Typography>
            ) : null}
            <Typography pt={1} variant="body2">
              <strong>Reason:</strong> {data.fraud_reason}
            </Typography>
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="center"
            mt={4}
            gap={2}
          >
            <LoadingButton
              size="medium"
              color="success"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={() => handleFraudPayout("approved")}
            >
              Approve
            </LoadingButton>
            <LoadingButton
              size="medium"
              color="error"
              loading={loading}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              variant="contained"
              type="button"
              onClick={() => handleFraudPayout("rejected")}
            >
              Reject
            </LoadingButton>
          </Stack>
        </DialogContent>
      </Dialog>
    </div>
  );
};

const DigitalPayouts = () => {
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [data, setData] = useState<any>([]);
  const [refreshLoader, setRefreshLoader] = useState(false);
  const location = useLocation();
  const params: any = new URLSearchParams(location.search);
  const [date, setDate] = useState<any>(
    params.get("date")
      ? parse(params.get("date"), "dd/MM/yyyy", new Date())
      : new Date()
  );
  const [selectedPayout, setSelectedPayout] = useState<any>(null);
  const [showFraudModal, setShowFraudModal] = useState(false);
  const [showReco, setShowReco] = useState(false);
  const [activePayoutId, setPayoutActiveId] = useState("");

  const fetchPayoutsData = async () => {
    setData([]);
    setLoading(true);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V2}/analytics/digital-payments/payouts?date=${format(
          date,
          "dd/MM/yyyy"
        )}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        setData(
          result.data.response?.map((d: any) => {
            return { ...d, extra_5: d.instant_charges ? "instant" : d.extra_5 };
          })
        );
      }
    } catch (err) {
      handleError(err);
    } finally {
      setLoading(false);
    }
  };

  const refreshStatus = async (index: number, payout_id: string) => {
    setRefreshLoader(true);
    setPayoutActiveId(payout_id);
    try {
      const result = await privateInstance.get(
        `${API_VERSION.V1}/orders/payments/payment-request/status/${payout_id}`
      );
      if (result?.data?.status === API_RESULT.SUCCESS) {
        const item = {
          ...result.data.response,
          extra_5: result.data.response?.instant_charges
            ? "instant"
            : result.data.response?.extra_5,
        };
        data.splice(index, 1, item);
        const updatedData = [...data];
        setData(updatedData);
      } else {
        errorToast("Sorry, we could not fetch status");
      }
    } catch (err) {
      handleError(err);
    } finally {
      setRefreshLoader(false);
      setPayoutActiveId("");
    }
  };

  useEffect(() => {
    fetchPayoutsData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  const cols: any = [
    {
      name: "fraud_detection",
      label: "Fraud Detection Status",
      options: {
        display: false,
        searchable: false,
      },
    },
    {
      name: "payout_id",
      label: "PAYOUT ID",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Stack direction="row" alignItems="center">
              <Typography
                sx={{
                  background: "transparent",
                  border: "none",
                  color: "#0058FF",
                  cursor: "pointer",
                  "&:disabled": {
                    color: "#000000DE",
                    cursor: "default",
                  },
                }}
                component="button"
                disabled={
                  data[tableMeta.rowIndex]?.fraud_detection !== "flagged"
                }
                onClick={() => {
                  setShowFraudModal(true);
                  setSelectedPayout(data[tableMeta.rowIndex]);
                }}
                variant="body2"
              >
                {value}
              </Typography>
              {data[tableMeta.rowIndex]?.fraud_detection === "flagged" ? (
                <Tooltip title="Suspicious Transaction">
                  <ErrorIcon color="error" fontSize="small" />
                </Tooltip>
              ) : null}{" "}
              {data[tableMeta.rowIndex]?.fraud_detection === "rejected" ? (
                <Tooltip title="Transaction Rejected">
                  <ErrorIcon color="warning" fontSize="small" />
                </Tooltip>
              ) : null}
            </Stack>
          );
        },
      },
    },
    {
      name: "extra_6",
      label: "Pharmacy Name",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography
              sx={{ textTransform: "capitalize" }}
              variant="body2"
              minWidth={200}
            >
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "location_id",
      label: "Location ID",
      options: {
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2" minWidth={160}>
              {value}
            </Typography>
          );
        },
      },
    },
    {
      name: "beneficiary.mobile",
      label: "Mobile No",
      options: {
        filter: false,
      },
    },
    {
      name: "payment_type",
      label: "Payment Type",
      options: {
        searchable: false,
        customBodyRender: (value: any, tableMeta: any) => {
          const pgPartner = data[tableMeta.rowIndex]?.pg_partner;
          return (
            <Typography variant="body2" minWidth={120}>
              {value}
              {pgPartner ? ` - ${pgPartner}` : ""}
            </Typography>
          );
        },
      },
    },
    {
      name: "amount",
      label: "AMOUNT (Rs.)",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Box display="flex" alignItems="center" gap={1}>
              <Typography variant="body2">{value}</Typography>
              {data[tableMeta.rowIndex]?.wallet_transactions ? (
                <Tooltip title="Wallet Transactions">
                  <Box
                    sx={{
                      border: "1px solid #2F7C31",
                      borderRadius: 2,
                      minWidth: "fit-content",
                      p: 0.5,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.25,
                    }}
                  >
                    <AccountBalanceWallet fontSize="small" color="success" />
                    <Typography sx={{ color: "#2F7C31" }} variant="body2">
                      &#8377;
                      {
                        data[tableMeta.rowIndex]?.wallet_transactions?.[0]
                          ?.amount
                      }
                    </Typography>
                  </Box>
                </Tooltip>
              ) : null}
            </Box>
          );
        },
      },
    },
    {
      name: "payment_status",
      label: "STATUS",
      options: {
        customBodyRender: (value: any, tableMeta: any) => {
          const error_message = data[tableMeta.rowIndex]?.error_message;
          const payout_id = data[tableMeta.rowIndex]?.payout_id;

          return (
            <Stack direction="row" alignItems="center">
              {value && (
                <Chip
                  size="small"
                  label={value}
                  sx={{
                    backgroundColor:
                      value === "success"
                        ? "#C6F6D5"
                        : value === "failed"
                        ? "#C53030"
                        : "#FBD38D",
                    padding: "6px",
                    color: value === "failed" ? "#fff" : "#000",
                  }}
                />
              )}
              {error_message ? (
                <Tooltip title={error_message}>
                  <InfoIcon sx={{ ml: 0.5 }} fontSize="small" color="error" />
                </Tooltip>
              ) : null}
              {value === PAYMENT_CREDIT_STATUS.PROCESSING && (
                <>
                  {refreshLoader && activePayoutId === payout_id ? (
                    <CircularProgress
                      sx={{ ml: 0.5 }}
                      size={16}
                      color="warning"
                    />
                  ) : (
                    <Tooltip title="Refresh status">
                      <span>
                        <IconButton
                          onClick={(e: any) => {
                            e.stopPropagation();
                            refreshStatus(tableMeta.rowIndex, payout_id);
                          }}
                        >
                          <AutorenewIcon color="warning" />
                        </IconButton>
                      </span>
                    </Tooltip>
                  )}
                </>
              )}
            </Stack>
          );
        },
      },
    },
    {
      name: "bank_reference",
      label: "Bank Ref",
      options: {
        filter: false,
      },
    },
    {
      name: "extra_5",
      label: "Mode",
      options: {},
    },
    {
      name: "extra_4",
      label: "Service",
      options: {},
    },
    {
      name: "payout_initiated_at",
      label: "Initiated At",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any) => {
          return (
            <Typography variant="body2" minWidth={160}>
              {parseAndFormateDate(value)}
            </Typography>
          );
        },
      },
    },
    {
      name: "modified_at",
      label: "Updated At",
      options: {
        searchable: false,
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <>
              <Typography variant="body2" minWidth={160}>
                {parseAndFormateDate(value)}
              </Typography>
              <Typography variant="body2" minWidth={160}>
                {data[tableMeta.rowIndex]?.created_by}
              </Typography>
            </>
          );
        },
      },
    },
    {
      name: "order_source",
      label: "Order Source",
      options: {
        searchable: false,
        filter: true,
      },
    },
  ];

  const options: any = {
    setRowProps: (row: any[], dataIndex: number, rowIndex: number) => {
      if (row?.[0] === "flagged") {
        return {
          style: { background: "#fed7d7" },
        };
      }
      return {};
    },
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "Payout ID/Name/Location ID/Mobile No/Bank Ref/Amount",
    rowsPerPageOptions: [10, 50, 100, 200],
    rowsPerPage: 10,
    searchText: params.get("id") ? params.get("id") : "",
    download: true,
    downloadOptions: {
      filename: "Payouts_" + new Date().toLocaleDateString(),
      filterOptions: {
        useDisplayedColumnsOnly: true,
        useDisplayedRowsOnly: true,
      },
    },
    expandableRows: true,
    expandableRowsHeader: false,
    expandableRowsOnClick: true,
    renderExpandableRow: (rowData: any, rowMeta: any) => {
      const fullRowData = data[rowMeta?.dataIndex];
      return (
        <tr style={{ backgroundColor: "#f6f6f6" }}>
          <td colSpan={rowData?.length}>
            <Stack
              sx={{
                borderBottom: "1px solid #e0e0e0",
              }}
              direction="row"
              gap={2}
            >
              {fullRowData?.beneficiary ? (
                <Stack sx={{ minWidth: "320px" }} p={2}>
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Beneficiary Details
                  </Typography>
                  <PaymentDataRow
                    dataTitle="Name"
                    value={fullRowData?.beneficiary?.name}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Mobile"
                    value={fullRowData?.beneficiary?.mobile}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Email"
                    value={fullRowData?.beneficiary?.email}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="UPI ID"
                    value={fullRowData?.beneficiary?.upi_id}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Account No"
                    value={fullRowData?.beneficiary?.account_number}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="IFSC Code"
                    value={fullRowData?.beneficiary?.ifsc_code}
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.penny_drop ? (
                <Stack
                  p={2}
                  sx={{ borderLeft: "1px solid #e0e0e0", minWidth: "280px" }}
                >
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Penny Drop Details
                  </Typography>
                  <PaymentDataRow
                    dataTitle="ACK No"
                    value={fullRowData?.penny_drop?.acknowledgment_number}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Bank Ref"
                    value={fullRowData?.penny_drop?.bank_reference}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Payment Type"
                    value={fullRowData?.penny_drop?.payment_type}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="PG Partner"
                    value={fullRowData?.penny_drop?.pg_partner}
                    smallFont={true}
                  />
                  {fullRowData?.penny_drop?.status ? (
                    <Stack
                      direction="row"
                      justifyContent="space-between"
                      alignItems="center"
                      pt={1}
                    >
                      <Typography
                        variant="body1"
                        fontWeight="bold"
                        sx={{ fontSize: "12px !important" }}
                      >
                        Status :
                      </Typography>
                      <Chip
                        size="small"
                        label={fullRowData?.penny_drop?.status}
                        sx={{
                          backgroundColor:
                            fullRowData?.penny_drop?.status === "success"
                              ? "#C6F6D5"
                              : fullRowData?.penny_drop?.status === "failed"
                              ? "#C53030"
                              : "#FBD38D",
                          padding: "6px",
                          color:
                            fullRowData?.penny_drop?.status === "failed"
                              ? "#fff"
                              : "#000",
                        }}
                      />
                    </Stack>
                  ) : null}
                  <PaymentDataRow
                    dataTitle="Created At"
                    value={
                      parseAndFormateDate(
                        fullRowData?.penny_drop?.created_at
                      ) as any
                    }
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.refund_details ? (
                <Stack sx={{ minWidth: "240px" }} p={2}>
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Pharmacy Refunds
                  </Typography>
                  <PaymentDataRow
                    dataTitle="Amount"
                    value={fullRowData?.refund_details?.refund_amount}
                    smallFont={true}
                  />
                </Stack>
              ) : null}

              {fullRowData?.instant_charges ? (
                <Stack
                  p={2}
                  sx={{
                    borderLeft: "1px solid #e0e0e0",
                    minWidth: "240px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "14px !important" }}
                    color="primary"
                    variant="h6"
                  >
                    Charges
                  </Typography>
                  <PaymentDataRow
                    dataTitle=" Amount"
                    value={fullRowData?.actual_amount}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Instant Charges"
                    value={fullRowData?.instant_charges}
                    smallFont={true}
                  />
                  <PaymentDataRow
                    dataTitle="Settlement Amount"
                    value={fullRowData?.amount}
                    smallFont={true}
                  />
                </Stack>
              ) : null}
            </Stack>
          </td>
        </tr>
      );
    },
    page: 1,
  };

  return (
    <>
      <Box
        mb={3}
        gap={4}
        display="flex"
        flexDirection={{ xs: "column", md: "row" }}
        justifyContent="space-between"
      >
        <Box
          display="flex"
          alignItems="center"
          sx={{ mt: 1.5, width: { xs: "100%", md: "50%" } }}
        >
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              label="Date"
              inputFormat="dd/MM/yyyy"
              value={date}
              onChange={(newValue: Date | null) => {
                setDate(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  name="date"
                  InputLabelProps={{
                    required: true,
                  }}
                  autoComplete="off"
                  onKeyDown={(e: any) => {
                    e.preventDefault();
                  }}
                />
              )}
            />
          </LocalizationProvider>
          <Button
            onClick={() => setShowReco(true)}
            variant="contained"
            color="primary"
            sx={{ ml: 1 }}
          >
            Reco Report{" "}
          </Button>
        </Box>
        {loading ? (
          <CircularProgress size={24} />
        ) : (
          <>
            {data && data.length > 0 ? (
              <Box sx={{ width: { xs: "100%", md: "50%" } }}>
                <Box
                  sx={{
                    boxShadow: "0 0 .25rem rgba(0,0,0,.075)",
                    padding: "10px",
                    borderRadius: "5px",
                  }}
                >
                  <Box pb={1} display="flex" alignItems="center" gap={1}>
                    <Typography>Recent Payouts</Typography>
                    {/* <Tooltip title="dummy text">
                      <InfoIcon color="primary" fontSize="small" />
                    </Tooltip> */}
                  </Box>
                  <Divider />
                  <Box pt={1}>
                    <Typography
                      sx={{
                        fontSize: "12px !important",
                      }}
                    >
                      Amount
                    </Typography>
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "28px !important" }}
                    >
                      &#8377;&nbsp;
                      {amountConverter(
                        data?.reduce(
                          (acc: number, curr: any) => acc + Number(curr.amount),
                          0
                        )
                      )}
                    </Typography>
                    <Typography
                      sx={{
                        fontSize: "12px !important",
                      }}
                    >
                      Count: <strong>{data?.length}</strong>
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ) : null}
          </>
        )}
      </Box>
      <ErrorBoundary>
        <MUIDataTable title="" data={data} columns={cols} options={options} />
      </ErrorBoundary>
      {showFraudModal ? (
        <FraudModal
          showFraudModal={showFraudModal}
          setShowFraudModal={setShowFraudModal}
          data={selectedPayout}
          callbackFn={() => fetchPayoutsData()}
        />
      ) : null}
      {showReco ? (
        <RecoReportModal showReco={showReco} setShowReco={setShowReco} />
      ) : null}
    </>
  );
};

export default DigitalPayouts;
