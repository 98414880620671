import {
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../../../components/Common/customDialogTitle";
import { commonTableOptions } from "../../../../constants";
import { API_RESULT, API_VERSION } from "../../../../enums/common.enum";
import { instance } from "../../../../utils/api";
import { getMuiTheme, handleError } from "../../../../utils/common";

const MerchantWiseTxnModal = ({
  date,
  selected,
  showTxnModal,
  setShowTxnModal,
}: any) => {
  const [data, setData] = useState<any>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getTxnData = async () => {
      let payload = {
        date: format(date, "yyyy-MM-dd"),
        metric_type: "pharmacy_transactions",
        location_id: selected?.location_id,
      };
      setLoading(true);
      try {
        const result = await instance.post(
          `${API_VERSION.V2}/analytics/digital-payments/overview`,
          payload
        );
        if (result.data?.status === API_RESULT.SUCCESS) {
          setData(result.data.response);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getTxnData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const cols = [
    {
      name: "transaction_id",
      label: "Transaction ID",
      options: {
        sort: false,
        filter: false,
        customBodyRender: (value: any) => {
          const txType =
            data?.transactions?.find((x: any) => x.transaction_id === value)
              ?.tx_type || "C";

          return (
            <Typography variant="body2">
              {value}{" "}
              <span
                style={{
                  fontWeight: "bold",
                  color: txType === "C" ? "green" : "red",
                }}
              >
                {txType === "C" ? "Cr" : "Dr"}
              </span>
            </Typography>
          );
        },
      },
    },
    {
      name: "terminal_id",
      label: "Terminal ID",
      options: {
        sort: false,
      },
    },
    {
      name: "amount",
      label: "Amount",
      options: {
        filter: false,
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        searchable: false,
        sort: false,
        customBodyRender: (value: any) => {
          return (
            <Chip
              size="small"
              label={value}
              sx={{
                backgroundColor:
                  value === "success"
                    ? "#C6F6D5"
                    : value === "failed"
                    ? "#C53030"
                    : "#FBD38D",
                padding: "6px",
                color: value === "failed" ? "#fff" : "#000",
              }}
            />
          );
        },
      },
    },
    {
      name: "bank_reference",
      label: "Bank Ref/UTR",
      options: { sort: false, filter: false },
    },
    {
      name: "customer_name",
      label: "Customer Name",
      options: { sort: false, filter: false },
    },
    {
      name: "created_at_time",
      label: "Time",
      options: {
        filter: false,
        customBodyRender: (value: any, tableMeta: any) => {
          return (
            <Typography minWidth={160} variant="body2">
              {data?.transactions[tableMeta.rowIndex]?.created_at_date} {value}
            </Typography>
          );
        },
      },
    },
  ];

  const options: any = {
    textLabels: {
      body: {
        noMatch: loading ? (
          <CircularProgress sx={{ color: "#0058ff" }} />
        ) : (
          "Sorry, there is no matching data to display"
        ),
      },
    },
    ...commonTableOptions,
    searchPlaceholder: "All",
    sort: true,
  };

  return (
    <div>
      <Dialog
        open={showTxnModal}
        onClose={() => setShowTxnModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullWidth
        maxWidth="xl"
      >
        <CustomDialogTitle
          id="customized-dialog-title"
          onClose={() => setShowTxnModal(false)}
        >
          {selected?.provider_name || selected?.beneficiary?.name} |&nbsp;
          {format(date, "dd-MM-yyyy")}
        </CustomDialogTitle>
        <DialogContent>
          <ThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title=""
              data={data?.transactions}
              columns={cols}
              options={options}
            />
          </ThemeProvider>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default MerchantWiseTxnModal;
