import HomeIcon from "@mui/icons-material/Home";
import ConsultationIcon from "@mui/icons-material/Vaccines";
import DiagnosticsIcon from "@mui/icons-material/Science";
import PharmacyIcon from "@mui/icons-material/Medication";
import CashlessIcon from "@mui/icons-material/PhoneIphone";
import SettingsIcon from "@mui/icons-material/AdminPanelSettings";
import ReportIcon from "@mui/icons-material/Assessment";
import UsersIcon from "@mui/icons-material/AccountCircle";
import OrderIcon from "@mui/icons-material/Settings";
import CreateIcon from "@mui/icons-material/Add";
import CashlessReportIcon from "@mui/icons-material/Money";
import AuditIcon from "@mui/icons-material/Margin";
import OrderReportIcon from "@mui/icons-material/FormatListNumbered";
import AssignmentIcon from "@mui/icons-material/Assignment";
import ManageIcon from "@mui/icons-material/GroupWork";
import BillIcon from "@mui/icons-material/AccountBalanceWallet";
import RecoveryIcon from "@mui/icons-material/SettingsBackupRestore";
import UserProfileIcon from "@mui/icons-material/Person";
import HelpIcon from "@mui/icons-material/Help";
import NewReleasesIcon from "@mui/icons-material/NewReleases";
import WhitelistIcon from "@mui/icons-material/Flaky";
import PaymentIcon from "@mui/icons-material/Payment";
import AgentRosterIcon from "@mui/icons-material/ManageAccounts";
import DunzoIcon from "@mui/icons-material/DeliveryDining";
import LogisticsIcon from "@mui/icons-material/ElectricRickshaw";
import TableChartIcon from "@mui/icons-material/TableChart";
import GroupIcon from "@mui/icons-material/Group";
import InsightsIcon from "@mui/icons-material/Insights";
import IPDIcon from "@mui/icons-material/Approval";
import EngineeringIcon from "@mui/icons-material/Engineering";
import PsychologyIcon from "@mui/icons-material/Psychology";
import OndcIcon from "@mui/icons-material/JoinInner";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppBlockingIcon from "@mui/icons-material/AppBlocking";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import BarChartIcon from "@mui/icons-material/BarChart";
import ListIcon from "@mui/icons-material/List";
import PreviewIcon from "@mui/icons-material/Preview";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import { Speaker } from "@mui/icons-material";

export const roles = [
  "super admin",
  "admin",
  "manager",
  "editor",
  "partner",
  "viewer",
];
export const status = ["active", "inactive"];

export const allSidebarItems = [
  {
    redirectTo: "/dashboard",
    Icon: HomeIcon,
    itemText: "Dashboard",
    menus: [],
  },
  {
    redirectTo: "",
    Icon: ConsultationIcon,
    itemText: "Consultation",
    menus: [
      {
        name: "Create",
        to: "/consultation/create",
        icon: CreateIcon,
      },
      {
        name: "Assign",
        to: "/consultation/assign?tab=1",
        icon: AssignmentIcon,
      },
      {
        name: "Manage",
        to: "/consultation/manage",
        icon: ManageIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: PharmacyIcon,
    itemText: "Pharmacy",
    menus: [
      {
        name: "Create",
        to: "/pharmacy/create",
        icon: CreateIcon,
      },
      {
        name: "Assign",
        to: "/pharmacy/assign?tab=1",
        icon: AssignmentIcon,
      },
      {
        name: "Manage",
        to: "/pharmacy/manage",
        icon: ManageIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: DiagnosticsIcon,
    itemText: "Diagnostics",
    menus: [
      {
        name: "Create",
        to: "/diagnostics/create",
        icon: CreateIcon,
      },
      {
        name: "Assign",
        to: "/diagnostics/assign?tab=1",
        icon: AssignmentIcon,
      },
      {
        name: "Manage",
        to: "/diagnostics/manage",
        icon: ManageIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: OndcIcon,
    itemText: "ONDC",
    menus: [
      {
        name: "Orders",
        to: "/ondc/orders",
        icon: ManageIcon,
      },
      {
        name: "IGM",
        to: "/ondc/igm",
        icon: HelpIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: IPDIcon,
    itemText: "IPD",
    menus: [
      {
        name: "Create",
        to: "/ipd/create",
        icon: CreateIcon,
      },
      {
        name: "Manage",
        to: "/ipd/manage",
        icon: ManageIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: CashlessIcon,
    itemText: "Cashless OPD",
    menus: [
      {
        name: "Bills",
        to: "/cashless-opd/bills",
        icon: BillIcon,
      },
      {
        name: "Recovery",
        to: "/cashless-opd/recovery",
        icon: RecoveryIcon,
      },
      {
        name: "User Profile",
        to: "/cashless-opd/user-profile",
        icon: UserProfileIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: DunzoIcon,
    itemText: "Dunzo",
    menus: [
      {
        name: "Orders",
        to: "/dunzo/orders",
        icon: ManageIcon,
      },
      {
        name: "Providers",
        to: "/dunzo/providers",
        icon: GroupIcon,
      },
    ],
  },
  {
    redirectTo: "",
    Icon: ReportIcon,
    itemText: "Reports",
    menus: [
      { name: "Orders", to: "/reports/orders", icon: OrderReportIcon },
      { name: "Cashless", to: "/reports/cashless", icon: CashlessReportIcon },
      { name: "Cart", to: "/reports/cart", icon: ShoppingCartIcon },
      { name: "Audit", to: "/reports/audit", icon: AuditIcon },
    ],
  },
  {
    redirectTo: "/payments?tab=1",
    Icon: PaymentIcon,
    itemText: "Payments",
    menus: [],
  },
  {
    redirectTo: "/logistics?tab=1",
    Icon: LogisticsIcon,
    itemText: "Logistics",
    menus: [],
  },
  {
    redirectTo: "",
    Icon: SettingsIcon,
    itemText: "Settings",
    menus: [
      {
        name: "Users",
        to: "/settings/users",
        icon: UsersIcon,
      },
      {
        name: "Admin-Panel",
        to: "/settings/admin-panel",
        icon: OrderIcon,
      },
      {
        name: "Whitelisting",
        to: "/settings/whitelisting?tab=1",
        icon: WhitelistIcon,
      },
      {
        name: "Roles",
        to: "/settings/roles",
        icon: PsychologyIcon,
      },
      {
        name: "Pincodes",
        to: "/settings/pincodes",
        icon: LocationOnIcon,
      },
    ],
  },
  {
    redirectTo: "/agent-roster",
    Icon: AgentRosterIcon,
    itemText: "Agent Roster",
    menus: [],
  },
  {
    redirectTo: "/ledger",
    Icon: TableChartIcon,
    itemText: "Ledger",
    menus: [],
  },
  {
    redirectTo: "/metrics",
    Icon: InsightsIcon,
    itemText: "Metrics",
    menus: [],
  },
  {
    redirectTo: "/api-metrics",
    Icon: BarChartIcon,
    itemText: "Api Metrics",
    menus: [],
  },
  {
    redirectTo: "/providers",
    Icon: EngineeringIcon,
    itemText: "Providers",
    menus: [],
  },
  {
    redirectTo: "/merchants",
    Icon: AssignmentIndIcon,
    itemText: "Merchants",
    menus: [],
  },
  {
    redirectTo: "",
    Icon: PaymentIcon,
    itemText: "Digital Payment",
    menus: [
      {
        name: "Overview",
        to: "/digital-payment/overview",
        icon: PreviewIcon,
      },
      {
        name: "Kyc Details",
        to: "/digital-payment/kyc-details",
        icon: PendingActionsIcon,
      },
      {
        name: "Devices",
        to: "/digital-payment/devices",
        icon: Speaker,
      },
      {
        name: "QR Terminals",
        to: "/digital-payment/qr-terminals",
        icon: ListIcon,
      },
      {
        name: "Transactions",
        to: "/digital-payment/transactions",
        icon: CurrencyRupeeIcon,
      },
      {
        name: "Payouts",
        to: "/digital-payment/payouts",
        icon: FormatListNumberedIcon,
      },
    ],
  },
  {
    redirectTo: "/app-issues",
    Icon: AppBlockingIcon,
    itemText: "App Issues",
    menus: [],
  },
  {
    redirectTo:
      "https://medpay.notion.site/Training-module-6fdfb381193942a48e296a5cf301d8ab",
    Icon: HelpIcon,
    itemText: "Help",
    menus: [],
  },
  {
    redirectTo:
      "https://medpay.notion.site/Order-Dashboard-Version-Updates-f4970fd6797d451a82b0f18aec2eb77b",
    Icon: NewReleasesIcon,
    itemText: "Latest Releases",
    menus: [],
  },
];

export const reportsEmailTimeInterval = 600; //seconds

export const cancellationReasonsForPharmacy = [
  "Cancelled by partner",
  "Customer not at the location",
  "Delivery not possible",
  "Invalid prescription",
  "Issue with delivery charges",
  "Issue with partial delivery",
  "Low order amount",
  "Order raised by mistake",
  "Patient has already purchased the medicine",
  "Patient looking for discount",
  "Patient looking for immediate delivery",
  "Patient not ready for alternatives",
  "Patient not responding",
  "Patient not willing to pay patient payable",
  "Patient wants COD",
  "Payment not done",
  "Psychiatric medicine/ medicine not to be sold online",
  "Unable to arrange for Medicine",
  "Unable to modify order",
  "Homeopathic/Ayurvedic Medicine",
  "Patient refused delivery",
  "Order not confirmed",
];

export const cancellationReasonsForDiagnostic = [
  "Delay in sample pick-up",
  "Home sample collection not available from preferred lab",
  "Home sample collection not possible for the patient",
  "No confirmation from partner",
  "Patient did not reach",
  "Patient not happy with alternative provider / timings",
  "Patient not willing to pay patient payable",
  "Payment not done",
  "Provider did not fulfill the booking",
  "Unable to modify order",
  "No confirmation from patient",
  "Patient refused pick-up",
  "Customer not willing to share the OTP",
  "Cashless service denied",
  "OTP not received to customer",
];

export const cancellationReasonsForConsultation = [
  "Doctor could not fulfill due to emergency",
  "No confirmation from partner",
  "Order raised with incorrect details",
  "Patient did not reach",
  "Patient not happy with alternate provider / timings",
  "Patient not willing to pay patient payable",
  "Payment not done",
  "Unable to modify order",
  "No confirmation from patient",
  "Customer not willing to share the OTP",
  "Cashless service denied",
  "OTP not received to customer",
  "Follow up consultations/No fee charged",
  "Visited different doctor/hospital/clinic",
  "Different specialty/location",
  "Duplicate/multiple orders",
  "Doctor not practising at the center",
  "Clinic address changed",
  "Clinic temporarily closed",
  "Clinic permanently closed",
  "Patient denied sharing prescription/bill",
  "Vaccination/Procedure request",
];

export const cancellationReasonsForOPD = [
  "Beneficiary name does not match the database",
  "Uploaded Bill not clear",
  "The bill uploaded is for a non-healthcare provider",
  "Payment details do not match the provider selected",
];

export const cancellationReasonsForPharmacyPartners = [
  "Patient not ready for alternatives",
  "Patient looking for immediate delivery",
  "Patient refused sample pick-up",
  "Patient has already purchased the medicine",
  "Patient looking for additional discount",
  "Issue with partial delivery",
  "Patient not happy with alternate provider / timings",
  "Patient wants COD",
  "Payment not done",
  "No confirmation from patient",
];

export const cancellationReasonsForPharmacyEka = [
  ...cancellationReasonsForPharmacyPartners,
  "Patient wanted loose medicines",
  "Doctor wants delivery from preferred pharmacy",
];

export const cancellationReasonsForHomeDel = [
  "Pharmacy Cancelled",
  "Rider Cancelled",
];

export const activateReasons = [
  "UPI/QR code matches with the pharmacy.",
  "Spoke with the pharmacy.",
];

export const deactivateReasons = [
  "Spoke with the pharmacy, UPI registered name does not belong to the Pharmacy.",
  "Invalid GST no.",
  "Pharmacy does not exists",
];

export const paymentCollectionTypes = {
  medpay: "Medpay",
  self: "Self",
};

export const deliveryPartnerTypes = {
  medpay: "Medpay",
  self_pickup: "Self Pickup",
};

export const labVisitTypes = {
  home_visit: "Home Visit",
  // self_visit: "Self Visit",
};

export const consultationTypes = {
  online: "Online",
  offline: "Offline",
};

export const genders = {
  male: "Male",
  female: "Female",
  others: "Others",
};

export const medicinePackForms = [
  "Ampoule",
  "Bag",
  "Basket",
  "Bottle",
  "Box",
  "Bucket",
  "Canister",
  "Cartridge",
  "Combo pack",
  "Disposable pen",
  "Flexpen",
  "Fliptop bottle",
  "Jar",
  "Kit",
  "Packet",
  "Pen",
  "Penfill",
  "Popup box",
  "Pouch",
  "Prefilled pen",
  "Prefilled syringe",
  "Pump bottle",
  "Rollon bottle",
  "Sachet",
  "Straw",
  "Strip",
  "Tin",
  "Tube",
  "Vial",
];

export const valueLimits = {
  MOBILE: 9999999999,
  PIN: 999999,
  QTY: 50,
  PACK_QTY: 999,
  MRP: 99999,
  PERCENT: 100,
  DUNZO_QTY: 50,
  AGE: 100,
  NAME_LEN: 50,
  PAYMENT_AMOUNT: 300000,
  IPD_BILL_AMOUNT: 2000000,
};

export const autogeneratePartnerOrderId = [
  "medpay",
  "kenko-health",
  "doconline",
  "icici",
  "loop health",
  "entitled",
];

export const degreeOptions = [
  "MBBS",
  "BDS",
  "BAMS",
  "BUMS",
  "BHMS",
  "BYNS",
  "B.V.Sc & AH",
  "MD",
  "DM",
  "MS",
  "MDS",
  "DNB",
  "D.M.",
  "M.Ch",
  "B.Pharm",
  "M.Pharm",
  "DMLT",
  "BMLT",
  "BOPTM",
  "Other",
];

export const paymentOptions = [
  "Card",
  "QR",
  "Voucher",
  "Internet Banking",
  "OPD Wallet",
  "Other",
];

export const upiOptions = {
  gpay: "Google Pay",
  paytm: "Paytm",
  phonepe: "Phonepe",
};

export const upiOptionsExtended = {
  ...upiOptions,
  bharatpe: "Bharat Pe",
  bhimupi: "BhimUPI",
};

export const thirdPartyOptions = [
  "Delivery Service",
  "Swiggy Genie",
  "Borzo",
  "Dunzo",
  "Provider",
  "Refund",
];

export const commonTableOptions = {
  filter: true,
  sort: false,
  responsive: "standard",
  selectableRows: "none",
  rowsPerPageOptions: [10, 25, 50],
  download: false,
  print: false,
  search: true,
  viewColumns: false,
  enableNestedDataAccess: ".",
  searchAlwaysOpen: true,
};

export const medicineDrugForms = [
  "aerosols",
  "aids",
  "alicaps",
  "ampules",
  "applicap",
  "aquanase",
  "aqueous",
  "ark",
  "astringent",
  "autohaler",
  "autopen",
  "bags",
  "balls",
  "balms",
  "band aid",
  "bandages",
  "bands",
  "barks",
  "bars",
  "beans",
  "belts",
  "berry",
  "bhasma",
  "bio-adhesive",
  "biscuits",
  "body wash",
  "bones",
  "book",
  "bottles",
  "boxes",
  "brush",
  "buds",
  "butter",
  "candies",
  "cannula",
  "caplets",
  "caps",
  "capsules",
  "captabs",
  "cartriges",
  "catheters",
  "cereals",
  "chew gum",
  "chewable tablet",
  "churna",
  "cigarettes",
  "cleaners",
  "cleanser",
  "combikit",
  "combipack",
  "conditioners",
  "condoms",
  "containers",
  "cookies",
  "corset",
  "cotton",
  "creams",
  "crusher",
  "cuff",
  "cups",
  "cushions",
  "dental cream",
  "dental gel",
  "denture cleaner",
  "depressors",
  "device+rotacap",
  "devices",
  "diapers",
  "dilutions",
  "diskettes",
  "dressing",
  "dropper",
  "drops",
  "dusting powder",
  "ear drop",
  "earplugs",
  "electrodes",
  "elixirs",
  "emulsions",
  "enema",
  "expectorant",
  "extract",
  "eye drop",
  "eye ointment",
  "eyeglasses",
  "face & body wash",
  "face wash",
  "facial foam",
  "films",
  "fingercots",
  "flakes",
  "floss",
  "flour",
  "flower",
  "foam",
  "food",
  "fruits",
  "gargle",
  "gauzes",
  "gel",
  "gels",
  "glasses",
  "globules",
  "gloves",
  "grains",
  "granules",
  "gum",
  "gum astringent",
  "gummies",
  "gums",
  "hair serum",
  "hair solution",
  "husk",
  "inahaler",
  "infusions",
  "inhalation",
  "inhaler",
  "injections",
  "insoles",
  "instacaps",
  "insulin",
  "jaggery",
  "jar",
  "jelly",
  "juice",
  "kits",
  "lacquer",
  "lancets",
  "laxative",
  "leaves",
  "legium",
  "lens",
  "linctus",
  "liniment",
  "lip balm",
  "liquids",
  "lotions",
  "lozenges",
  "majoon",
  "masks",
  "massage oil",
  "massagers",
  "mat",
  "microgranules",
  "moisturiser",
  "mouth paint",
  "mouth wash",
  "muesli",
  "multihaler",
  "murabba",
  "nasal drop",
  "nasal spray",
  "nasal wash",
  "nasofilter",
  "nebulisers",
  "needles",
  "net",
  "nexcaps",
  "nipples",
  "novocart",
  "novolizer",
  "nuts",
  "oats",
  "octahaler",
  "oils",
  "ointments",
  "ops",
  "opticops",
  "oral drop",
  "oral emulsion",
  "oxipule",
  "oxycaps",
  "pacifier",
  "packs",
  "pads",
  "paints",
  "pan",
  "panties",
  "particles",
  "pastes",
  "pastilles",
  "patches",
  "pellets",
  "pens",
  "pessaries",
  "pillows",
  "plan",
  "plaster",
  "pot",
  "pouches",
  "powders",
  "pregnancy card",
  "pulp",
  "pump",
  "rapitabs",
  "redicaps",
  "resin",
  "respicaps",
  "respules",
  "revolizer",
  "rheocap",
  "rice",
  "root",
  "rotacaps",
  "sachets",
  "salt",
  "sandals",
  "sanitizer",
  "scrubs",
  "seeds",
  "serums",
  "servings",
  "shaker",
  "shampoos",
  "sheets",
  "shoes",
  "sipper",
  "skin serum",
  "slippers",
  "snacks",
  "soaps",
  "socks",
  "softgels",
  "softlet",
  "softules",
  "solutions",
  "spacer",
  "sprays",
  "sticks",
  "stockings",
  "strap",
  "straws",
  "strips",
  "sugar",
  "sun protection lotion",
  "supports",
  "suppositories",
  "suspensions",
  "swabs",
  "syringes",
  "syrups",
  "system",
  "tabcaps",
  "tablets",
  "tampons",
  "tapes",
  "tea",
  "teethers",
  "tinctures",
  "tip cleaner",
  "tissues",
  "toners",
  "tonics",
  "tooth powder",
  "toothbrushes",
  "toothpastes",
  "transcaps",
  "transhaler",
  "transpules",
  "tubes",
  "units",
  "vaccine",
  "vegicaps",
  "vials",
  "walker",
  "wash",
  "wax",
  "wheelchair",
  "wipes",
  "wood bark",
  "wool",
];

export const logisticsPartners = [
  {
    name: "Borzo",
    value: "borzo",
    cod: true,
    support: false,
    isActive: true,
  },
  {
    name: "Dunzo",
    value: "dunzo",
    cod: true,
    support: true,
    isActive: false,
  },
  {
    name: "Porter",
    value: "porter",
    cod: false,
    support: false,
    isActive: true,
  },
  {
    name: "Zomato",
    value: "zomato",
    cod: true,
    support: false,
    isActive: false,
  },
];

export const medpayTeams = [
  {
    label: "Support Team",
    value: "support_team",
  },
  {
    label: "Field Team",
    value: "field_team",
  },
  { label: "IT Team", value: "it_team" },
  {
    label: "Third Party",
    value: "third_party",
  },
];

export const soundboxProvidersList = ["cashfree", "cwd", "fidypay"];
