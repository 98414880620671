import { LoadingButton } from "@mui/lab";
import {
  Box,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomDialogTitle from "../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../enums/common.enum";
import useAxiosInstance from "../../hooks/useAxiosInstance";
import useUserInfo from "../../hooks/useUserInfo";
import { AuthenticatedUser } from "../../interfaces/user.model";
import { errorToast, handleError, successToast } from "../../utils/common";
import { CertificateView } from "./certificateView";

const UpdateCertModal = ({
  updateCert,
  setUpdateCert,
  activeLocationId,
  callBack,
}: any) => {
  const [data, setData] = useState<any>(null);
  const [loading, setLoading] = useState(false);
  const [uploading, setUploading] = useState(false);
  const privateInstance = useAxiosInstance();
  const [kycFound, setKycFound] = useState(false);
  const [genPaymentQr, setGenPaymentQr] = useState("yes");
  const [rejectReason, setRejectReason] = useState("");
  const [chosenAction, setChosenAction] = useState("");
  const user: AuthenticatedUser = useUserInfo();

  const updateLocation = async () => {
    const storeImage: any = document
      .getElementById("store_front_0")
      ?.getAttribute("data-url");
    const gstCert: any = document
      .getElementById("gst_certificate_0")
      ?.getAttribute("data-url");
    const licenseCert: any = document
      .getElementById("license_certificate_0")
      ?.getAttribute("data-url");

    let payload: any = {
      approved_by: user?.user_id,
    };

    if (storeImage) {
      payload = {
        ...payload,
        store_front_images: [storeImage],
      };
    }

    if (gstCert) {
      payload = {
        ...payload,
        gst_certificate: gstCert,
      };
    }

    if (licenseCert) {
      payload = {
        ...payload,
        license_certificate: licenseCert,
      };
    }

    if (chosenAction) {
      payload = {
        ...payload,
        qc_status: chosenAction,
      };

      if (chosenAction === "approved") {
        payload = {
          ...payload,
          generate_qr: genPaymentQr,
        };
      }

      if (chosenAction === "rejected") {
        if (rejectReason.trim().length === 0) {
          errorToast("Please specify rejection reason");
          return;
        }
        payload = {
          ...payload,
          rejection_reason: rejectReason,
          generate_qr: "no",
        };
      }
    }

    if (kycFound) {
      if (Object.keys(payload).length === 0) {
        errorToast("Please upload a file to update");
        return;
      }
    } else {
      if (!storeImage) {
        errorToast("Please upload store front image");
        return;
      }
      if (!gstCert && !licenseCert) {
        errorToast("Please upload either gst or license certificate");
        return;
      }
    }

    if (window.confirm("Are you sure? This will replace existing data!")) {
      setUploading(true);
      try {
        const result = await privateInstance.patch(
          `${API_VERSION.V1}/manage/locations/${activeLocationId}`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Updated Successfully");
          setUpdateCert(false);
          if (callBack) {
            callBack();
          }
        }
      } catch (err) {
        handleError(err);
      } finally {
        setUploading(false);
      }
    }
  };

  useEffect(() => {
    const getKycData = async () => {
      setLoading(true);
      try {
        const result = await privateInstance.get(
          `${API_VERSION.V1}/orders/provider/kyc?location_id=${activeLocationId}`
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          setKycFound(true);
          setData(result.data?.response);
        } else {
          setKycFound(false);
        }
      } catch (err) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    };
    getKycData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Dialog
      open={updateCert}
      onClose={() => setUpdateCert(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="md"
    >
      <CustomDialogTitle
        id="customized-dialog-title"
        onClose={() => setUpdateCert(false)}
      >
        <Stack direction="row">
          <Typography variant="h5" pr={1}>
            KYC Documents
          </Typography>
          {data?.status ? (
            <>
              {data.status === "pending" ? (
                <Chip
                  size="small"
                  label="PENDING"
                  sx={{ backgroundColor: "#FBD38D" }}
                />
              ) : data.status === "completed" ? (
                <Chip
                  size="small"
                  label="COMPLETED"
                  sx={{ backgroundColor: "#C6F6D5" }}
                />
              ) : (
                <Chip
                  size="small"
                  label="REJECTED"
                  sx={{ backgroundColor: "#C53030", color: "#FFF" }}
                />
              )}
            </>
          ) : null}
        </Stack>
      </CustomDialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            {[
              {
                url: data?.store_front_images?.[0],
                title: "Store Image",
                uploadType: "store_front",
              },
              {
                url: data?.gst_certificate,
                title: "GST Certificate",
                uploadType: "gst_certificate",
              },
              {
                url: data?.license_certificate,
                title: "License Certificate",
                uploadType: "license_certificate",
              },
            ].map((x: any, i: number) => {
              return (
                <CertificateView
                  key={i}
                  activeLocationId={activeLocationId}
                  url={x.url}
                  title={x.title}
                  uploadType={x.uploadType}
                />
              );
            })}
            {data?.modified_at ? (
              <Typography pt={1} sx={{ fontSize: "12px !important" }}>
                Modified: {data?.modified_at}
                {data?.modified_by ? ` | ${data.modified_by}` : ""}
              </Typography>
            ) : null}
            {data?.status === "pending" ? (
              <>
                {chosenAction === "" ? (
                  <Box display="flex" justifyContent="center" mt={3} gap={2}>
                    <LoadingButton
                      size="medium"
                      color="success"
                      loading={uploading}
                      disabled={
                        data?.store_front_images?.length === 0 ||
                        !data?.license_certificate
                      }
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      variant="contained"
                      type="button"
                      onClick={() => setChosenAction("approved")}
                    >
                      Approve
                    </LoadingButton>
                    <LoadingButton
                      size="medium"
                      color="error"
                      loading={uploading}
                      loadingIndicator={
                        <CircularProgress size={20} sx={{ color: "#fff" }} />
                      }
                      variant="contained"
                      type="button"
                      onClick={() => setChosenAction("rejected")}
                    >
                      Reject
                    </LoadingButton>
                  </Box>
                ) : (
                  <>
                    {chosenAction === "approved" ? (
                      <FormControl>
                        <RadioGroup
                          row
                          aria-labelledby="demo-row-radio-buttons-group-label"
                          name="row-radio-buttons-group"
                          value={genPaymentQr}
                          onChange={(e: any) => setGenPaymentQr(e.target.value)}
                        >
                          <FormLabel sx={{ mt: 1, mr: 1 }}>
                            Generate Payment QR:
                          </FormLabel>
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    ) : (
                      <FormControl fullWidth sx={{ mt: 3 }}>
                        <TextField
                          id="rejection-reason"
                          label="Rejection Reason"
                          multiline
                          rows={3}
                          variant="outlined"
                          value={rejectReason}
                          onChange={(e) => setRejectReason(e.target.value)}
                          fullWidth
                        />
                      </FormControl>
                    )}
                  </>
                )}
              </>
            ) : null}

            {data?.status !== "pending" ||
            (data?.status === "pending" && chosenAction !== "") ? (
              <Box textAlign="center" mt={3}>
                <LoadingButton
                  size="medium"
                  color="primary"
                  loading={uploading}
                  loadingIndicator={
                    <CircularProgress size={20} sx={{ color: "#fff" }} />
                  }
                  variant="contained"
                  type="button"
                  onClick={updateLocation}
                >
                  Submit
                </LoadingButton>
              </Box>
            ) : null}
          </>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default UpdateCertModal;
