import { LoadingButton } from "@mui/lab";
import {
  CircularProgress,
  Dialog,
  DialogContent,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
} from "@mui/material";
import React, { useState } from "react";
import CustomDialogTitle from "../../../components/Common/customDialogTitle";
import { API_RESULT, API_VERSION } from "../../../enums/common.enum";
import useAxiosInstance from "../../../hooks/useAxiosInstance";
import { handleError, successToast } from "../../../utils/common";
import { soundboxProvidersList } from "../../../constants";

const AddDeviceModal = ({ open, onClose, getData }: any) => {
  const [soundboxId, setSoundboxId] = useState("");
  const [soundboxProvider, setSoundboxProvider] = useState("");
  const [loading, setLoading] = useState(false);
  const privateInstance = useAxiosInstance();

  const handleSubmit = async () => {
    let payload: any = {
      type: "add_device",
      device_id: soundboxId,
      partner: soundboxProvider,
    };

    if (window.confirm("Are you sure?")) {
      setLoading(true);
      try {
        const result = await privateInstance.post(
          `${API_VERSION.V2}/analytics/digital-payments/providers`,
          payload
        );
        if (result?.data?.status === API_RESULT.SUCCESS) {
          successToast("Added Successfully");
          onClose();
          getData();
        }
      } catch (err: any) {
        handleError(err);
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullWidth
      maxWidth="sm"
    >
      <CustomDialogTitle id="customized-dialog-title" onClose={onClose}>
        Add Device
      </CustomDialogTitle>
      <DialogContent>
        <>
          <>
            <TextField
              fullWidth
              id="soundbox_id"
              label="Device ID/Serial No"
              size="small"
              value={soundboxId}
              onChange={(e) => setSoundboxId(e.target.value)}
              inputProps={{
                maxLength: 20,
              }}
              InputLabelProps={{
                required: true,
              }}
              sx={{ mt: 1.5 }}
            />
            <FormControl size="small" fullWidth sx={{ mt: 2 }}>
              <InputLabel required id="soundbox-provider-label">
                Soundbox Provider
              </InputLabel>
              <Select
                labelId="soundbox-provider-label"
                name="soundbox_provider"
                id="soundbox_provider"
                value={soundboxProvider}
                label="Soundbox Provider"
                onChange={(e: SelectChangeEvent) =>
                  setSoundboxProvider(e.target.value)
                }
                sx={{ textTransform: "uppercase" }}
              >
                {soundboxProvidersList.map((x, i) => {
                  return (
                    <MenuItem
                      sx={{ textTransform: "uppercase" }}
                      key={i}
                      value={x}
                    >
                      {x}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </>
          <Stack mt={3} alignItems="center">
            <LoadingButton
              color="primary"
              variant="contained"
              loading={loading}
              disabled={loading || !soundboxId?.trim() || !soundboxProvider}
              loadingIndicator={
                <CircularProgress size={20} sx={{ color: "#fff" }} />
              }
              onClick={() => handleSubmit()}
            >
              Add
            </LoadingButton>
          </Stack>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AddDeviceModal;
